<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Brands - {{this.form.type}} new entity
			el-form(:form="form")
				el-row(:gutter="12")
					el-col(:xs="24", :sm="12" :md="8")
						el-form-item(label="Name" :error="errors.name.s ? errors.name.m : ''" v-bind:class="{'is-invalid': errors.name.s}")
							el-input(v-model="form.name" placeholder="Brand name")
					el-col(:xs="24", :sm="12" :md="12")
						el-form-item(label="Brand URL" :error="errors.url.s ? errors.url.m : ''" v-bind:class="{'is-invalid': errors.url.s}")
							el-input( v-model="form.url" placeholder="Brand URL")
					//			el-col(:xs="24", :sm="5")
					//el-col(:xs="24", :sm="12" :md="4")
						el-form-item(label="Allow Duplicates" label-position="top")
							el-radio-group.w-100(v-model="form.allow_duplicates")
								el-radio(:label="true") On
								el-radio(:label="false") Off
				el-divider
				el-row(:gutter="12")
					el-col(:xs="24" :sm="12")
						el-form-item(label="Link to brand logo" :error="errors.img_src.s ? errors.img_src.m : ''" v-bind:class="{'is-invalid': errors.img_src.s}")
							el-input(v-model="form.img_src" placeholder="URL link to the image logo")
					el-col(:xs="24" :sm="12")
						el-form-item(label="Upload logo")
							el-upload.upload-demo(
								drag="" :action="`${api_path}file/upload`" w thumbnail-mode="" :disabled="list.length >= 1" v-bind:class="{'disabled-field': list.length >= 5}" :on-success="handleSuccess" :file-list="fileList" multiple="" :headers="auth" :limit="3" :before-upload="beforeUpload")
								i.el-icon-upload
								.el-upload__text
									| Drop file here or
									em click to upload
								.el-upload__tip(slot='tip') jpg/png files with a size less than 4MB - Max 5 files
				el-row(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel


</template>


<style lang="scss">

</style>


<script lang="js">
const VALID = [
	'image/png',
	'image/bmp',
	'image/jpg',
	'image/jpeg',
	'image/gif',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];

import validator from 'validator';
import Vue from 'vue';

let clearBeforeSend = (data) => {
	let body = {...data};
	delete body.type;
	return body;
};

export default {
	name: 'brand-form',
	async beforeRouteEnter(to, from, next) {
		if (to.name === 'iframe-add-brand') {
			next();
		} else if (to.name === 'iframe-edit-brand') {
			try {
				let result = await Vue.apix.sendHttpRequest('GET', 'brand/get', {id: to.params.id});
				next(vm => {
					vm.entity_id = to.params.id;
					vm.form = {
						type: 'edit',
						name: result.name,
						url: result.url,
						allow_duplicates: result.allow_duplicates,
						img_src: result.img_src
					};
				});
			} catch (e) {
				Vue.router.push({name: 'brand-list'})
			}
		}
	},
	data() {
		return {
			form: {
				type: 'add',
				name: '',
				url: '',
				img_src: '',
				allow_duplicates: false,
			},
			errors: {
				name: {s: false, m: ''},
				img_src: {s: false, m: ''},
				url: {s: false, m: ''}
			},
			busy: false,
			valid: VALID,
			fileList: [],
			list: [],
		}
	},
	computed: {
		api_path() {
			return process.env.VUE_APP_BASE_URL
		},
		auth() {
			return {Authorization: `Bearer ${this.$store.state.AUTH_TOKEN}`}
		},
	},
	methods: {
		async submit() {
			if (!this.validate()) return;
			try {
				if (this.form.type === 'add') {
					let result = await this.$apix.sendHttpRequest('POST', `brand/create`, {data: clearBeforeSend(this.form)})
					if (result.id) this.$router.push({name: 'iframe-list'})
				} else {
					let result = await this.$apix.sendHttpRequest('PUT', `brand/${'update/' + this.entity_id}`, {data: clearBeforeSend(this.form)})
					this.$router.push({name: 'iframe-list'})
				}
			} catch (e) {
				console.error(e);
			}
		},
		validate() {
			this.errors = {name: {s: false, m: ''}, img_src: {s: false, m: ''}, url: {s: false, m: ''}};
			let error = false;

			if (this.form.name === '') {
				this.errors.name = {s: true, m: 'Name cannot be empty'};
				error = true;
			} else if (!validator.isAscii(this.form.name)) {
				this.errors.name = {s: true, m: 'Name must be alphanumeric'};
				error = true;
			}

			if (this.form.url === '') {
				this.errors.url = {s: true, m: 'URL cannot be empty'};
				error = true;
				// } else if(!validator.isURL(this.form.url)) {
				//     this.errors.url = {s:true, m: 'URL malformed'};
				//     error = true;
				// }

				/*if(this.form.img_src === '') {
						this.errors.img_src = {s:true, m: 'img_src cannot be empty'};
						error = true;
				} else*/ /*if(this.form.img_src !== '' && !validator.isURL(this.form.img_src)) {
                    this.errors.img_src = {s:true, m: 'img_src malformed'};
                    error = true;*/
			}

			return !error;
		},
		handleSuccess() {
			// TODO - assign the link to the brand
		},
		beforeUpload(file) {
			if (!this.valid.includes(file.type)) {
				this.$message.error('Document file must be a picture, PDF or an office document!');
				return false
			}

			if (file.size / 1024 / 1024 > 4) {
				this.$message.error('Document file size can not exceed 4MB!');
				return false
			}
		},
		downloadFile(name) {
			window.open(`${this.serverPath}${name}`)
		},
	}
}
</script>
